import { createSelector } from 'reselect';
import { extractParentCategory, getProductById } from 'redux/modules/entities/selectors/products';
import { getProductConflictById } from 'redux/modules/conflicts/selectors';

import { trackViewAlternativesClick } from 'components/Conflict/Resolution/ViewAlternatives';
import { CHANGE_SLOT_DATE, CHANGE_SLOT_TYPE } from 'constants/restrictions';
import { getEditSlotUrl } from 'redux/modules/slot-booking/selectors/get-edit-slot-url';
import { GTM_TRACKED_CONFLICTS } from 'constants/conflicts';

import { routerLinkWithProps } from 'utils/productLinkProps';

//
// making DS conflict
//

// DS conflict resolution constructors

const viewAlternativesResolution = (productData, analyticsData) => {
  const { url } = extractParentCategory(productData);
  return url
    ? {
        type: 'link',
        label: 'View alternatives',
        action: routerLinkWithProps({
          to: url,
          onClick: trackViewAlternativesClick(productData, analyticsData),
        }),
      }
    : undefined;
};

const editSlotResolution = url => ({
  type: 'link',
  label: 'Change slot',
  action: routerLinkWithProps({
    to: url,
  }),
});

const removeFromTrolleyResolution = {
  type: 'button',
  label: 'Remove from trolley',
  action: {
    /* fill in component or hook */
  },
};

// DS conflict constructor

const makeDSConflict = ({ message, resolution, outOfStock }) =>
  message
    ? { message, ...(resolution ? { resolution } : {}), ...(outOfStock ? { outOfStock } : {}) }
    : undefined;

// convert: wtr-website Conflict => wtr-ingredients Conflict

export const makeGetDSConflict = () =>
  createSelector(
    getProductConflictById,
    getProductById,
    getEditSlotUrl,
    (_state, _productId, isInTrolley) => isInTrolley,
    (_state, _productId, _isInTrolley, analyticsData) => analyticsData,
    (conflict, productData, editSlotUrl, isInTrolley, analyticsData) => {
      if (!conflict) return undefined;

      // Out Of Stock and temporarily unavailable conflict (resolution: view alternative or remove from trolley)
      if (
        conflict.outOfStock ||
        GTM_TRACKED_CONFLICTS.includes(conflict.messages?.shortItem?.toLowerCase())
      ) {
        return makeDSConflict({
          message: conflict.outOfStock ? 'Sold out online' : conflict.messages?.shortItem,
          resolution: isInTrolley
            ? removeFromTrolleyResolution
            : viewAlternativesResolution(productData, {
                ...analyticsData,
                conflictMessage: conflict.messages?.shortItem,
              }),
          outOfStock: conflict.outOfStock,
        });
      }

      // Delivery not possible conflict (reslution: change slot date/type)
      if ([CHANGE_SLOT_TYPE, CHANGE_SLOT_DATE].includes(conflict.resolutionActions[0]?.id)) {
        return makeDSConflict({
          message: conflict.messages.shortItem,
          resolution: editSlotResolution(editSlotUrl),
        });
      }

      return conflict?.messages?.shortItem
        ? makeDSConflict({ message: conflict.messages.shortItem })
        : undefined;
    },
  );
