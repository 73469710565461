export const RECOMMENDED_CAROUSEL_CONTEXT_TYPE = 'recommendedCarousel';
export const GA_RECOMMENDED_CAROUSEL_TYPE = 'Recommendation Carousel';
export const GA_MEAL_DEAL_RECOMMENDED_CAROUSEL_TYPE = 'Meal Deal Carousel';
export const GA_GLP_RECOMMENDED_CAROUSEL_TYPE = 'GLP Carousel';
export const GA_TROLLEY_RECOMMENDED_CAROUSEL_TYPE = 'Trolley Carousel';
export const GA_FAVS_RECOMMENDED_CAROUSEL_NAME = 'My Favourites Chosen For You';
export const GA_SHOP_PREVIOUS_ORDER_RECOMMENDED_CAROUSEL_NAME = 'Shop From Previous Order';
export const GA_PDP_RECOMMENDED_CAROUSEL_NAME = 'Product Description Carousel';

export const makeRecommendedCarouselContext = ({
  name = GA_FAVS_RECOMMENDED_CAROUSEL_NAME,
  type = GA_RECOMMENDED_CAROUSEL_TYPE,
}) => ({
  type: RECOMMENDED_CAROUSEL_CONTEXT_TYPE,
  personalisation: { type, name },
});
