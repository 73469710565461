import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel } from '@johnlewispartnership/wtr-ingredients/ingredients/Carousel';
import { ProductPod } from 'components/ProductPod2';
import { BREAKPOINTS } from 'constants/grid';
import { useClientSideMediaQuery } from 'hooks/use-client-side-media-query';

import { makeRecommendedCarouselContext } from 'utils/clickContext/recommendedCarouselContext';
import styles from './RecommendedCarousel.scss';

export default function RecommendedCarousel({
  titleDesktop,
  titleMobileAndTablet,
  onSlideView,
  subTitle,
  products,
  className,
  ariaName,
  nameForAnalytics,
  typeForAnalytics,
  backgroundColor,
  buttonsTheme,
  divider,
  linkData,
  image,
  podVariant,
}) {
  const clickContext = makeRecommendedCarouselContext({
    name: nameForAnalytics || ariaName || titleDesktop,
    type: typeForAnalytics,
  });

  const isMobile = useClientSideMediaQuery({ maxWidth: BREAKPOINTS.lg });

  const title = isMobile ? titleMobileAndTablet : titleDesktop;

  return (
    <div className={classNames(className, styles.recommendedCarousel)} data-testid="rec-prods">
      <Carousel
        ariaName={ariaName}
        backgroundColor={backgroundColor}
        title={title}
        buttonsTheme={buttonsTheme}
        divider={divider}
        subTitle={subTitle}
        linkData={linkData}
        onSlideView={onSlideView}
        image={image}
      >
        {products.map(({ searchProduct, sponsored }, i) => (
          <ProductPod
            key={searchProduct}
            lineNumber={searchProduct}
            position={i + 1}
            sponsored={sponsored}
            clickContext={clickContext}
            imageLoading={i >= 4 ? 'lazy' : null}
            variant={podVariant}
          />
        ))}
      </Carousel>
    </div>
  );
}

RecommendedCarousel.displayName = 'RecommendedCarousel';

RecommendedCarousel.propTypes = {
  titleDesktop: PropTypes.string.isRequired,
  titleMobileAndTablet: PropTypes.string.isRequired,
  onSlideView: PropTypes.func,
  subTitle: PropTypes.string,
  ariaName: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      searchProduct: PropTypes.string,
      position: PropTypes.number,
      sponsored: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  nameForAnalytics: PropTypes.string,
  typeForAnalytics: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonsTheme: PropTypes.oneOf(['dark', 'light']),
  divider: PropTypes.bool,
  linkData: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
    newWindow: PropTypes.bool,
    type: PropTypes.oneOf(['link', 'button']),
  }),
  image: PropTypes.shape({
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
  }),
  podVariant: 'light' || 'regular' || 'slim',
};

RecommendedCarousel.defaultProps = {
  onSlideView: undefined,
  products: [],
  subTitle: undefined,
  ariaName: undefined,
  className: undefined,
  nameForAnalytics: undefined,
  typeForAnalytics: undefined,
  backgroundColor: undefined,
  buttonsTheme: 'dark',
  divider: false,
  linkData: undefined,
  image: undefined,
  podVariant: 'light',
};
