import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import classNames from 'classnames';

import styles from './ErrorPanel.scss';

const ErrorPanel = pure(({ children, dim, message, problem, reason }) => (
  <section className={classNames('container-fluid', styles.panel, { [styles.dim]: dim })}>
    {message ? (
      <h2 className={styles.problem}>{problem}</h2>
    ) : (
      <h1 className={styles.problem}>{problem}</h1>
    )}
    {reason && (
      <aside>
        <p className={styles.reason}>{reason}</p>
      </aside>
    )}
    {children && <p className={styles.children}>{children}</p>}
  </section>
));

ErrorPanel.displayName = 'ErrorPanel';

ErrorPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  dim: PropTypes.bool,
  message: PropTypes.bool,
  problem: PropTypes.string.isRequired,
  reason: PropTypes.string,
};

ErrorPanel.defaultProps = {
  children: undefined,
  dim: false,
  message: false,
  reason: null,
};

export default ErrorPanel;
